/**
 * by Florian Knop, granted under MIT-License
 * https://github.com/fknop/angular-pipes/blob/master/src/math/bytes.pipe.ts
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

import { __ } from '../functions/object.functions';

/**
 * Transforms the date string input to a more readable format to understand how much time has passed since the date
 * in the format of just now, hours ago or days ago
 *
 * @export
 */
@Pipe({
    name: 'safe',
    standalone: false
})
export class SafePipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) { }

    transform(content: string, type: 'html' | 'resource' | 'script' | 'style' | 'url'): string | SafeValue {
        if (__.IsNullOrUndefinedOrEmpty(content)) {
            return '';
        }
        switch (type) {
            case 'html':
                return this.domSanitizer.bypassSecurityTrustHtml(content);
            case 'resource':
                return this.domSanitizer.bypassSecurityTrustResourceUrl(content);
            case 'script':
                return this.domSanitizer.bypassSecurityTrustScript(content);
            case 'style':
                return this.domSanitizer.bypassSecurityTrustStyle(content);
            case 'url':
                return this.domSanitizer.bypassSecurityTrustUrl(content);
            default:
                break;
        }
    }
}
