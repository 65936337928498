import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '../../../base/components/base-component';
import { GenericMenuItem } from '../../generic-menu/generic-menu-item.model';

/**
 * This component is responsible for the display of standardized dialog content. Optionally, generic menu
 * items can be provided, which will be displayed inbetween the title and the close icon button.
 *
 * @export
 */
@Component({
    selector: 'intello-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    standalone: false
})
export class DialogHeaderComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // This tooltip text will be displayed inside the tooltip of the close icon button
  @Input() closeTooltipText: string = 'Close the dialog';

  @Input() closeIsVisible: boolean = true;

  // Determines whether the divider should be visible or not
  @Input() dividerIsVisible: boolean = false;

  // If set an icon should be shown at the beginning of the title 
  @Input() icon: string;

  // These generic menu items will be displayed inbetween the title and the close icon button
  @Input() items: GenericMenuItem[] = [];

  @Input() title: string = '';

  @Input() closeTooltipDisabled: boolean = true;

  @Input() closeTooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'above';

  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() emitCloseClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
