export enum AppRoute {
  Account = 'account',
  Affiliate = 'affiliates',
  ContentElements = 'content-elements',
  Courses = 'courses',
  Files = 'files',
  Forbidden = 'forbidden',
  Groups = 'groups',
  Home = 'home',
  Ideas = 'ideas',
  Languages = 'languages',
  Lessons = 'lessons',
  Login = 'login',
  Modules = 'modules',
  Channels = 'channels', 
  Users = 'users',
  BillingAccounts = 'billing-accounts',
  Orders = 'orders',
  Invoices = 'invoices'
}
